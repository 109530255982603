import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { Link } from 'react-router-dom'; // Import de Link
import { globalConfig } from 'config';
import useMainInformation from 'features/setup/services/MainInformation';
import { useQuery } from 'react-query';
import { IGaleryPhoto, IMainInformation, defaultMainInformation } from 'features/setup/models/MainInformation';
import { isFalsy } from 'utility-types';
import { Stack } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon  from '@mui/icons-material/Instagram';
import TwitterIcon  from '@mui/icons-material/Twitter';
import LinkedInIcon  from '@mui/icons-material/LinkedIn';
import { IClass } from 'features/setup/models/MainInformation';

export const Footer = () => {
  const { getMainInformations, getGaleryPhotos } = useMainInformation();
  const { data: mainInformations } = useQuery<IMainInformation[]>(['MainInformation'], () => getMainInformations());
  const { data: galeryPhotos } = useQuery<IGaleryPhoto[]>(['GaleryPhoto'], () => getGaleryPhotos());
  const { t } = useTranslation();

  const [mainInformation, setMainInformation] = useState<IMainInformation>(defaultMainInformation);
  useEffect(() => {
    if (!isFalsy(mainInformations) && mainInformations?.length > 0)
      setMainInformation(mainInformations[0]);
  }, [mainInformations]);

  const [photos, setPhotos] = useState<IGaleryPhoto[]>([]);
  useEffect(() => {
    setPhotos(galeryPhotos || []);
  }, [galeryPhotos]);

  const { getClass } = useMainInformation();
  const { data: classes } = useQuery<IClass[]>(['Class'], () => getClass());
  const [classe, setClasses] = useState<IClass[]>([]);


  useEffect(() => {
    setClasses(classes || []);
}, [classes]);

  return (
    <Box
      sx={{
        display: 'flex',
        borderTop: '1px solid #ddd',
        background: '#231F20',
        py: 2,
        px: 4,
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Container maxWidth='xl'>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6} lg={3}>
            <Typography variant='body1' sx={{ fontFamily: 'Poppins', fontWeight: 'bold', color: 'white' }} noWrap>
              {t('Produits')}
            </Typography>
            {/* Affichage des catégories ici */}
            {classe.map((classe, index) => (
              <Link key={index} to={`/category/${classe.id}/${classe.nomClasse}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                <Typography sx={{ mt: 1, color: 'white' }}>
                  {classe.nomClasse}
                </Typography>
              </Link>
            ))}
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
          <Stack sx={{ flexDirection: 'column' }}>
      <Box sx={{ mt: 2, width: '100%' }} >
        <Typography variant='body1' sx={{ fontFamily: 'Poppins', fontWeight: 'bold' }} id="tableTitle" color="white" noWrap>
          {`${t(('Horaires'))} `}
        </Typography>
      </Box>

      <Typography sx={{ marginTop: '6px' }}></Typography>

      <Box sx={{ mt: 0.25, width: '100%' }} >
        <Typography component="h3" sx={{ fontFamily: 'Poppins', fontWeight: '500' }} id="tableTitle" color="white" noWrap>
          {`${t(('Lundi - Vendredi ............ 8h30 à 18h30'))} `}
        </Typography>
      </Box>
      <Box sx={{ mt: 0.25, width: '100%' }} >
        <Typography component="h3" sx={{ fontFamily: 'Poppins', fontWeight: '500' }} id="tableTitle" color="white" noWrap>
          {`${t(('Samedi ........................... 8h30 à 15h30'))} `}
        </Typography>
      </Box>
      
    </Stack>
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
          <Stack sx={{ flexDirection: 'column' }}>
      <Box sx={{ mt: 2, width: '100%' }} >
        <Typography variant='body1' sx={{ fontFamily: 'Poppins', fontWeight: 'bold' }} id="tableTitle" color="white" noWrap>
          {`${t(('Informations'))} `}
        </Typography>
      </Box>

      <Typography sx={{ marginTop: '6px' }}></Typography>

      <Link to={'/aboutUs'} style={{ marginTop: 0.25, width: '100%', textDecoration:'none' }} >
        <Typography component="h3" sx={{ fontFamily: 'Poppins', fontWeight: '500' }} id="tableTitle" color="white" noWrap>
          Qui sommes nous
        </Typography>
      </Link>
      <Box sx={{ mt: 0.25, width: '100%' }} >
        <Typography component="h3" sx={{ fontFamily: 'Poppins', fontWeight: '500' }} id="tableTitle" color="white" noWrap>
          Rejoignez notre équipe
        </Typography>
      </Box>
      <Box sx={{ mt: 0.25, width: '100%' }} >
        <Typography component="h3" sx={{ fontFamily: 'Poppins', fontWeight: '500' }} id="tableTitle" color="white" noWrap>
          Prépation de projets
        </Typography>
      </Box>

      <Link to={'/services'} style={{ marginTop: 0.25, width: '100%', textDecoration:'none' }} >
        <Typography component="h3" sx={{ fontFamily: 'Poppins', fontWeight: '500' }} id="tableTitle" color="white" noWrap>
          Nos services
        </Typography>
      </Link>

      <Link to={'/contact'} style={{ marginTop: 0.25, width: '100%', textDecoration:'none' }} >
        <Typography component="h3" sx={{ fontFamily: 'Poppins', fontWeight: '500' }} id="tableTitle" color="white" noWrap>
          Contactez-nous
        </Typography>
      </Link>

      <Link to={'#'} style={{ marginTop: 0.25, width: '100%', textDecoration:'none' }} >
        <Typography component="h3" sx={{ fontFamily: 'Poppins', fontWeight: '500' }} id="tableTitle" color="white" noWrap>
         Politique et confidentialié
        </Typography>
      </Link>
    </Stack>
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
          <Stack flexDirection='column'  >
            <Box sx={{ mt: 0.25, width: '100%', display: 'flex', justifyContent: 'justify' }} > 
              <Link style={{ fontFamily: 'Poppins', textDecoration: 'none', marginRight: '25px' }} to={'/'}>
                <img src={`${globalConfig.get().apiUrl}/download/${mainInformation.logo_Url}`} alt="Logo" style={{ width: 120, borderRadius: '5%' }} />

              </Link>       
                                                        
            </Box>
            <Typography>
                <Link to={'#'} target="_blank" style={{ textDecoration: 'none', color: '#3b5998', marginRight: '10px' }}>
                    <FacebookIcon sx={{ fontSize: '30px' }} />
                </Link>
                <Link to={'#'} target="_blank" style={{ textDecoration: 'none', color: '#e4405f', marginRight: '10px' }}>
                    <InstagramIcon sx={{ fontSize: '30px' }} />
                </Link>
                <Link to={'#'} target="_blank" style={{ textDecoration: 'none', color: '#1da1f2', marginRight: '10px' }}>
                    <TwitterIcon sx={{ fontSize: '30px' }} />
                </Link>
                <Link to={'#'} target="_blank" style={{ textDecoration: 'none', color: '#0077b5' }}>
                    <LinkedInIcon sx={{ fontSize: '30px' }} />
                </Link>
            </Typography>  
          </Stack>
          </Grid>
        </Grid>
      </Container>
      <Divider />
      <Box sx={{ mt: 0.25, width: '100%' }}>
        <Typography component="h3" sx={{ fontFamily: 'Poppins', fontWeight: '500', color: '#fff', textAlign: "center", alignItems: 'center' }} id="tableTitle" noWrap>
          {`${t(('Copyright © 2024 ECDS Sarl tous droits réservés'))} `}
        </Typography>
      </Box>
    </Box>
  );
};
