// Importations nécessaires
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';
import { Link } from 'react-router-dom';
import { globalConfig } from 'config';

// Définition des interfaces
interface Category {
  id: number;
  nomCategorie: string;
  parentContentId: number;
  image: string;
  image_Url: string;
}

interface SubCategory {
  id: number;
  productId: number;
  parentContentId: number;
  nomSousCategorie: string;
  image: string;
  image_Url: string;
}



// Définition du composant CategoryPage
const CategoryPage: React.FC = () => {
  // Récupération de l'ID de la classe à partir de l'URL
  const { categoryId, categoryName } = useParams<{ categoryId: string, categoryName: string }>();

  // Déclaration de l'état pour stocker les catégories liées à cette classe
  const [categories, setCategories] = useState<SubCategory[]>([]);
  const [categorName, setCategoryName] = useState<string>('');
 


  // Effet pour charger les catégories liées à cette classe
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        // Requête à l'API pour récupérer les catégories associées à la classe spécifique
        const response = await axios.get<SubCategory[]>(`https://skrapi-api.univ-soft.com/api/production/content/v1/uivso3e8mh70hadagairiyamswx/sub-category/get-contents?parentContentId=${categoryId}`);
        // Mise à jour de l'état avec les catégories récupérées
        setCategories(response.data);

        const categoryResponse = await axios.get<Category>(`https://skrapi-api.univ-soft.com/api/production/content/v1/uivso3e8mh70hadagairiyamswx/category/get-contents/${categoryId}`);
        // Mise à jour de l'état avec le nom de la classe récupéré
        setCategoryName(categoryResponse.data.nomCategorie);


      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    // Appel de la fonction fetchCategories
    fetchCategories();
  }, [categoryId]);

  // Rendu des catégories
  return (
    <div style={{ background: '#f7f7f7' }}>
      
      <Container maxWidth='xl' sx={{ marginTop: '50px' }}>
      <Typography sx={{marginTop:'10px'}} >
    </Typography>

      <Typography variant="h4" component="h1" sx={{color:'#922790', fontFamily:'Poppins'}}>
      {categoryName} 
      </Typography>
        <Grid container spacing={2} sx={{marginTop:'20px'}}>
          {categories.map(category => (
            <Grid item xs={12} md={3} key={category.id}>
              <Card>
                <CardContent>
                  <Link to={`/produit/${category.id}/${category.nomSousCategorie}`} style={{ textDecoration: 'none', textAlign: 'center', color: '#922790' }}>
                    <Typography variant="h6" component="h3">
                      {category.nomSousCategorie}
                    </Typography>
                  </Link>
                  <hr />
                  <Link to={`/produit/${category.id}/${category.nomSousCategorie}`}>
                    <img
                      src={`${globalConfig.get().apiUrl}/download/${category.image_Url}`}
                      style={{ width: '100%', height: '200px', objectFit: 'cover', display: 'flex', justifyContent: 'center' }}
                      alt={category.nomSousCategorie}
                    />
                  </Link>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Typography sx={{ marginTop: '40px' }}></Typography>
      </Container>
    </div>
  );
};

// Exportation du composant CategoryPage
export default CategoryPage;
