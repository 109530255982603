import React, { useEffect, useState } from 'react';

import Box  from '@mui/material/Box';

import MenuIcon from '@mui/icons-material/Menu';

import AddBusinessRoundedIcon from "@mui/icons-material/AddBusinessRounded";
import Typography from '@mui/material/Typography';


import {
  flexBetweenCenter,
  dFlex,
  displayOnDesktop,
  displayOnMobile,
} from 'themes/commonStyles';

import { AppBar,  Divider,  Drawer,  IconButton,List,ListItem,ListItemIcon,Toolbar,Accordion, AccordionSummary, AccordionDetails, ListItemText } from '@mui/material';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Link, useNavigate, useParams } from 'react-router-dom';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import SousMenus from './SousMenus';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useMainInformation from 'features/setup/services/MainInformation';
import { useQuery } from 'react-query';
import { IClass } from 'features/setup/models/MainInformation';
import { globalConfig } from 'config';





export const HeaderMenu = () => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event : any) => {    
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }
  const [subMenuOpen, setSubMenuOpen] = useState(false);

  const handleSubMenuOpen = () => {
    setSubMenuOpen(true);
  };
  
  const handleSubMenuClose = () => {
    setSubMenuOpen(false);
  };

  const { getClass } = useMainInformation();
  const { data: classes } = useQuery<IClass[]>(['Class'], () => getClass());
  const [classe, setClasses] = useState<IClass[]>([]);


  useEffect(() => {
    setClasses(classes || []);
}, [classes]);

 


 
 
    return (
      <AppBar position="static" sx={{ backgroundColor: 'white', height:'75px' }}>
      <Toolbar>
      <Grid container alignItems="center" justifyContent={"space-around"}>
  <Grid item xs={10} container justifyContent="space-around" sx={{ display: { xs: 'none', md: 'flex' } }}>
  <Button color="inherit" component={Link} to="/" sx={{ color: 'black', fontWeight: '500', fontSize: '0.95rem', fontFamily:'Poppins', '&:hover': { color: '#922790' }, margin: '0 ' }}>{t("Accueil").toUpperCase()}</Button>
  <Button
  color="inherit"
  onMouseEnter={handleSubMenuOpen}
  onMouseLeave={handleSubMenuClose}
  sx={{
    color: 'black',
    fontWeight: '500',
    fontSize: '0.95rem', 
    fontFamily:'Poppins',
    '&:hover': { color: '#DBA82F' }
  }}
>
  {t('Produits').toUpperCase()}
  {subMenuOpen && (
    <Box sx={{ position: 'absolute', top: '100%', left: 0 }}>
      <SousMenus onClose={handleSubMenuClose} open={true} />
    </Box>
  )}
</Button>

    <Button color="inherit" component={Link} to="/aboutUs" sx={{ color: 'black', fontWeight: '500', fontSize: '0.95rem', fontFamily:'Poppins', '&:hover': { color: '#922790' }, margin: '0 ' }}>{t(" A Propos de nous").toUpperCase()}</Button>
    <Button color="inherit" component={Link} to="/services" sx={{ color: 'black', fontWeight: '500', fontSize: '0.95rem', fontFamily:'Poppins', '&:hover': { color: '#922790' }, margin: '0 ' }}>{t("Nos Services ").toUpperCase()}</Button>
    <Button
    component={Link} to="/contact"
      color="inherit"
      sx={{
        color: 'black',
        fontWeight: '500',
        fontSize: '0.95rem', 
        fontFamily:'Poppins',
        '&:hover': { color: '#922790' },
        margin: '0'
      }}
    >
      {t('Contact').toUpperCase()}
    </Button>
  </Grid>

  <Grid item xs={10} container justifyContent="flex-end" sx={{ display: { xs: 'flex', md: 'none' } }}>
      <IconButton onClick={handleClick} edge="start" sx={{ color: '#000', fontSize: '45px' }} aria-label="menu">
        <MenuIcon sx={{ color: '#000', fontSize: '55px' }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflowY: 'auto',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >

        <MenuItem onClick={() => { navigate('/'); handleClose(); }}>
          <Typography variant="body1" fontWeight="500" fontFamily="Poppins" color="black" fontSize='25px' textAlign='center'>
            Accueil
          </Typography>
        </MenuItem>
        <Divider />

        <MenuItem onClick={(event) => event.stopPropagation()}>
  <Accordion>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1a-content"
      id="panel1a-header"
      onClick={(event) => event.stopPropagation()}
      onFocus={(event) => event.stopPropagation()}
    >
      <Typography variant="body1" fontWeight="500" fontFamily="Poppins" color="black" fontSize='25px' textAlign='center'>
        Produits
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
    <List>
  {classe.map((classItem) => (
    <ListItem key={classItem.id} sx={{ display: 'flex', flexDirection: 'column' }}>
      <Link to={`/category/${classItem.id}/${classItem.nomClasse}`} style={{ textDecoration: 'none', color: '#000', fontFamily: 'Poppins', display: 'flex', alignItems: 'center' }} onClick={handleClose}>
        {classItem.image_Url && (
          <img
            src={`${globalConfig.get().apiUrl}/download/${classItem.image_Url}`}
            style={{ width: '30px', height: '30px', objectFit: 'fill', marginRight: '10px' }}
            alt={classItem.nomClasse}
          />
        )}
        <Typography sx={{ fontSize: '20px', lineHeight: '50px' }}>{classItem.nomClasse}</Typography>
      </Link>
      <Divider sx={{width:'300px'}}/>
    </ListItem>
  ))}
</List>




    </AccordionDetails>
  </Accordion>
</MenuItem>

        <Divider />
        
        <MenuItem onClick={() => { navigate('/aboutUs'); handleClose(); }} >
          <Typography variant="body1" fontWeight="500" fontFamily="Poppins" color="black" fontSize='25px' textAlign='center'>
            A propos de nous
          </Typography>
        </MenuItem>
        <Divider />

        <MenuItem onClick={() => { navigate('services'); handleClose(); }}>
          <Typography variant="body1" fontWeight="500" fontFamily="Poppins" color="black" fontSize='25px' textAlign='center'>
            Nos services
          </Typography>
        </MenuItem>
        <Divider />

        <MenuItem onClick={() => { navigate('contact'); handleClose(); }}>
          <Typography variant="body1" fontWeight="500" fontFamily="Poppins" color="black" fontSize='25px' textAlign='center'>
            Contact
          </Typography>
        </MenuItem>
        <Divider />
        {/* Autres éléments de menu ici */}
      </Menu>
    </Grid>

        </Grid>
        
      </Toolbar>
    </AppBar>
    );
  }
