import React, { useState, useEffect } from 'react';
import { Popover, List, ListItem, Grid, Typography, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { globalConfig } from 'config';
import { IClass, ICategories, ISubCategory } from 'features/setup/models/MainInformation';
import useMainInformation from 'features/setup/services/MainInformation';
import { useQuery } from 'react-query';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

interface ServicesSubMenuProps {
  open: boolean;
  onClose: () => void;
}

const SousMenus: React.FC<ServicesSubMenuProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { getClass, getCategory, getSubCategory } = useMainInformation();
  const { data: classes } = useQuery<IClass[]>(['Class'], () => getClass());
  const { data: categories } = useQuery<ICategories[]>(['Categories'], () => getCategory());
  const { data: subCategories } = useQuery<ISubCategory[]>(['SubCategory'], () => getSubCategory());

  const [hoveredClass, setHoveredClass] = useState<string>('');
  const [hoveredCategory, setHoveredCategory] = useState<string>('');
  const [hoveredSubCategory, setHoveredSubCategory] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [filteredCategories, setFilteredCategories] = useState<ICategories[]>([]);
  const [filteredSubCategories, setFilteredSubCategories] = useState<ISubCategory[]>([]);

  useEffect(() => {
    console.log("Classes:", classes);
    console.log("Categories:", categories);
    console.log("SubCategories:", subCategories);
    
    if (classes && hoveredClass && categories) {
      const filteredCats = categories.filter(cat => cat.parentContentId === classes.find(c => c.nomClasse === hoveredClass)?.id);
      setFilteredCategories(filteredCats);
    }
  }, [classes, hoveredClass, categories]);

  // Effect to handle loading state based on filtered subcategories
  // Effect to handle loading state based on filtered subcategories
useEffect(() => {
  if (subCategories) {
    const subCategoriesCopy = [...subCategories];
    setFilteredSubCategories(subCategoriesCopy);
    setLoading(false); // Mettre à jour l'état loading une fois que les sous-catégories sont disponibles
  }
}, [subCategories]);


// Effect to handle category change
useEffect(() => {
  console.log("Hovered Class:", hoveredClass);
  console.log("Hovered Category:", hoveredCategory);
  console.log("Hovered SubCategory:", hoveredSubCategory);
  
  const firstCategory = categories?.find(category => category.parentContentId === classes?.find(c => c.nomClasse === hoveredClass)?.id);
  if (firstCategory) {
    setHoveredCategory(firstCategory.nomCategorie);
  }
}, [hoveredClass, classes, categories]);
  
useEffect(() => {
  const firstSubCategory = subCategories?.find(subCategory => subCategory.parentContentId === categories?.find(c => c.nomCategorie === hoveredCategory)?.id);
  if (firstSubCategory) {
    setHoveredSubCategory(firstSubCategory.nomSousCategorie);
  }
}, [hoveredCategory, categories, subCategories]);
  

  const handleClassHover = (className: string) => {
    setHoveredClass(className);
    setHoveredCategory('');
    setHoveredSubCategory('');
  };

  const handleCategoryHover = (categoryName: string) => {
    setHoveredCategory(categoryName);
    setHoveredSubCategory('');
  };

  const handleSubCategoryHover = (subCategoryName: string) => {
    setHoveredSubCategory(subCategoryName);
  };

  return (
    <Popover
    open={open}
    onClose={onClose}
    anchorReference="anchorPosition"
    anchorPosition={{ top: 182, left: 200 }}
    PaperProps={{ sx: { width: '100%', height: 450 } }}
  >
    {loading ? (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <CircularProgress />
      </div>
    ) : (
      <List sx={{ display: 'flex', flexDirection: 'row' }}>
        <ListItem sx={{ marginRight: '30px', width: 300 }}>
          <List sx={{ overflowY: 'auto', maxHeight: '400px', width: '1000px' }}>
            {classes && classes.map((classItem) => (
              <ListItem
                key={classItem.id}
                onMouseEnter={() => handleClassHover(classItem.nomClasse)}
                sx={{ display: 'flex', alignItems: 'center', paddingY: '10px' }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '5px',
                    borderRadius: '4px',
                    backgroundColor: hoveredClass === classItem.nomClasse ? '#BFBFBF' : 'transparent',
                  }}
                >
                   {classItem.image_Url && (
                <img
                  src={`${globalConfig.get().apiUrl}/download/${classItem.image_Url}`}
                  style={{ width: '30px', height: '30px', objectFit: 'fill' }}
                  alt={classItem.nomClasse}
                />
              )}
                <Link to={`/category/${classItem.id}/${classItem.nomClasse}` } key={classItem.id} style={{ textDecoration: 'none' }} onClick={onClose}>
                  <Typography
                    component="span"
                    sx={{
                      fontFamily: 'Poppins',
                      color: hoveredClass === classItem.nomClasse ? '#000' : '#000',
                      fontSize: '20px',
                      marginLeft: '10px',
                    }}
                  >
                  
                    {t(classItem.nomClasse)}
                  </Typography>
                  {hoveredClass === classItem.nomClasse && <ArrowRightIcon sx={{ color: '#000' }} />}
                  </Link>
                </div>
              </ListItem>
            ))}
          </List>
        </ListItem>
  
        <ListItem sx={{ width: '100%', margin: '20px' }}>
          <Grid container spacing={2} sx={{ overflowY: 'auto', maxHeight: '400px', position: 'fixed', top: '215px' }}>
            {filteredCategories.map((category) => (
              <Grid item xs={12} md={2} key={category.id} sx={{ width: 'calc(20% - 60px)', marginRight: '60px' }}>
                <Link
                  to={`/subcategory/${category.id}/${category.nomCategorie}`}
                  style={{ textDecoration: 'none', color: 'inherit', display: 'flex' }}
                  onMouseEnter={() => handleCategoryHover(category.nomCategorie)}
                  onClick={onClose}
                >
                  <Typography variant='body1' sx={{ fontFamily: 'Poppins', fontWeight: '700' }}>
                    {t(category.nomCategorie)}
                  </Typography>
                </Link>
  
                <List>
                  {filteredSubCategories
                    .filter(subCategory => subCategory.parentContentId === category.id)
                    .map((subCategory) => (
                      <ListItem
                        key={subCategory.id}
                        sx={{
                          fontFamily: 'Poppins',
                          fontSize: '16px',
                          color: (hoveredSubCategory === subCategory.nomSousCategorie) ? '#000' : '#000',
                        }}
                        onMouseEnter={() => handleSubCategoryHover(subCategory.nomSousCategorie)}
                        onClick={onClose}
                      >
                        <Link
                          to={`/produit/${subCategory.id}/${subCategory.nomSousCategorie}`}
                          style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                          {t(subCategory.nomSousCategorie)}
                        </Link>
                      </ListItem>
                    ))}
                </List>
              </Grid>
            ))}
          </Grid>
        </ListItem>
      </List>
    )}
  </Popover>
  
  );
};

export default SousMenus;
