import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Container, Button } from '@mui/material';
import { globalConfig } from 'config';

// Définition des interfaces
interface SubCategory {
    id: number;
    nomSousCategorie: string;
    parentContentId: number;
    image: string;
    image_Url: string;
}

interface CategoryProduct {
    id: number,
    nom: string,
    description:string,   
    image: string,
    image_Url: string 
}

const Products: React.FC = () => {
    const { subcategoryId, nomSousCategorie } = useParams<{ subcategoryId: string, nomSousCategorie: string }>();
    const [products, setProducts] = useState<CategoryProduct[]>([]);
    const [subCategoryName, setSubCategoryName] = useState<string>('');

    const [hoveredProductId, setHoveredProductId] = useState<number | null>(null);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get<CategoryProduct[]>(`https://skrapi-api.univ-soft.com/api/production/content/v1/uivso3e8mh70hadagairiyamswx/products/get-contents?parentContentId=${subcategoryId}`);
                setProducts(response.data);

                const subcategoryResponse = await axios.get<SubCategory>(`https://skrapi-api.univ-soft.com/api/production/content/v1/uivso3e8mh70hadagairiyamswx/sub-category/get-contents/${subcategoryId}`);
                setSubCategoryName(subcategoryResponse.data.nomSousCategorie);
                
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchProducts();
    }, [subcategoryId]);

    return (
        <div style={{ background: '#f7f7f7' }}>
           
            <Container maxWidth='xl' sx={{ marginTop: '50px' }}>
            <Typography sx={{marginTop:'10px'}} ></Typography>
            <Typography variant="h4" component="h1" sx={{color:'#922790', fontFamily:'Poppins'}}>
                {nomSousCategorie}
            </Typography>
                <Grid container spacing={2} sx={{marginTop:'20px'}}>
                    {products.map(product => (
                        <Grid item xs={12} md={3} key={product.id}>
                            <Card
                                sx={{
                                    position: 'relative',
                                    margin: '0 auto',
                                    cursor: 'pointer',
                                    width: '100%',
                                    height: '600px',
                                    borderRadius: '8px',
                                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                    transition: 'transform 0.2s',
                                    '&:hover': { transform: 'scale(1.05)' }
                                }}
                                onMouseEnter={() => setHoveredProductId(product.id)}
                                onMouseLeave={() => setHoveredProductId(null)}
                            >
                                <Typography sx={{ marginTop: '20px' }}>
                                </Typography>
                                <img
                                    src={`${globalConfig.get().apiUrl}/download/${product.image_Url}`}
                                    style={{ width: '100%', height: '250px', objectFit: 'cover' }}
                                    alt={product.image}
                                />
                                <CardContent>
                                    <Typography variant="h6" sx={{ fontFamily: 'Poppins', fontWeight: '900', fontSize: '16px', textAlign: 'center' }}>
                                        {product.nom}
                                    </Typography>
                                    <Typography variant="h6" sx={{ fontFamily: 'Poppins', fontWeight: '500', fontSize: '14px', textAlign: 'justify' }}>
                                        {product.description}
                                    </Typography>
                                    {hoveredProductId === product.id && (
                                        <Button variant="contained" onClick={() => window.location.href = '#'} sx={{ marginTop: '20px', width: '100%', fontFamily: 'Poppins', height: '55px' }}>Acheter maintenant</Button>
                                    )}
                                    <Typography sx={{ marginTop: '40px' }}>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
                <Typography sx={{ marginTop: '70px' }}>
                </Typography>
            </Container>
        </div>
    );
};

export default Products;
