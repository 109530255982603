// Importations nécessaires
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';
import { Link } from 'react-router-dom';
import { globalConfig } from 'config';

// Définition des interfaces
interface Category {
  id: number;
  productId: number;
  parentContentId: number;
  nomCategorie: string;
  image : string;
  image_Url: string;
}

interface ClassItem {
  id: number;
  nomClasse: string;
}

// Définition du composant Category
const CategoryPage: React.FC = () => {
  // Récupération de l'ID de la classe à partir de l'URL
  const { classId, classeName } = useParams<{ classId: string, classeName:string }>();

  // Déclaration de l'état pour stocker les catégories liées à cette classe
  const [categories, setCategories] = useState<Category[]>([]);
  // Déclaration de l'état pour stocker le nom de la classe
  const [className, setClassName] = useState<string>('');

  // Effet pour charger les catégories et les détails de la classe liée à cette classe
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Vérifier si classId est défini
        if (classId) {
          // Requête à l'API pour récupérer les catégories associées à la classe spécifique
          const categoriesResponse = await axios.get<Category[]>(`https://skrapi-api.univ-soft.com/api/production/content/v1/uivso3e8mh70hadagairiyamswx/category/get-contents?parentContentId=${classId}`);
          // Mise à jour de l'état avec les catégories récupérées
          setCategories(categoriesResponse.data);

          // Requête à l'API pour récupérer les détails de la classe en fonction de son ID
          const classResponse = await axios.get<ClassItem>(`https://skrapi-api.univ-soft.com/api/production/content/v1/uivso3e8mh70hadagairiyamswx/class/get-contents/${classId}`);
          // Mise à jour de l'état avec le nom de la classe récupéré
          setClassName(classResponse.data.nomClasse);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Appel de la fonction fetchData
    fetchData();
  }, [classId]);

  // Rendu des catégories
  return (
    <div style={{background:'#f7f7f7'}}>
      
      <Container maxWidth='xl' sx={{marginTop:'40px'}}>
      <Typography sx={{marginTop:'10px'}} >
    
      </Typography>
      <Typography variant="h4" component="h1" sx={{color:'#922790', fontFamily:'Poppins'}} >
        {classeName}
      </Typography>
        <Grid container spacing={2} sx={{marginTop:'20px'}} >
          {categories.map(category => (
            <Grid item xs={6} md={3} key={category.id}>
              <Card >
                <CardContent >
                  <Link to={`/subcategory/${category.id}/${category.nomCategorie}`}  style={{textDecoration:'none',textAlign:'center', color:'#922790'}}>
                    <Typography variant="h6" component="h3" >
                      {category.nomCategorie}
                    </Typography>
                  </Link>
                  <hr />
                  <Link to={`/subcategory/${category.id}`}  style={{textDecoration:'none',textAlign:'center'}}>
                    <img
                      src={`${globalConfig.get().apiUrl}/download/${category.image_Url}`}
                      style={{ width: '100%', height: '200px', objectFit: 'cover' }}
                      alt={category.nomCategorie}
                    />
                  </Link>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

// Exportation du composant CategoryPage
export default CategoryPage;
